export const metaConstants = {
    title: "Earlyjobs-India's No.1 Portal for remote HR recruiters",
    description: "Anyone looking for the HR Recruiter job or internship and wanting to work from home can join Earlyjobs.",
    keywords: "earlyjobs,early jobs, earlycareer, early career, internships for students, part-time jobs India, entry-level jobs, freshers job opportunities, online internships, remote internships, summer internships 2024, student job portal, job listings for graduates, skill development jobs, flexible jobs for students, work-from-home jobs, career starter jobs, part-time work for teens, college internships, startup jobs India, apprenticeship opportunities, job search for freshers, early career jobs, resume building tips, interview preparation resources, job fairs in India, networking for students, job placement assistance, freelance opportunities for students, professional internships, virtual internships 2024, career guidance for students, skill-based jobs, jobs in tech for freshers, internships in marketing, internships in finance, part-time jobs near me, job application tips, startup internships, jobs in education for students, short-term jobs India, opportunities for young professionals, job listings for high school students.",
    applyAsARecruiter: {
        title: "Apply as a recruiter - Recruiter Job - earlyjobs.in",
        description: "Work From Home Part time recruitment Freelancer Remote working opportunities for Recruitment Executives. Freelancing for Recruiters & Interns.",
        keywords: "freelance recruiter jobs, recruiter internships in India, work from home recruiter jobs, part-time recruiter positions, online recruiting internships, freelance talent acquisition, remote recruitment opportunities, part-time HR recruitment, flexible recruiter jobs, internship for recruitment roles, freelance hiring positions, virtual recruiter jobs, part-time work for recruiters, recruiting from home, entry-level freelance recruiter, work from home talent scout, recruiter internships for students, online job recruitment internships, freelance sourcing specialists, part-time recruitment consultancy, work from home HR roles, internship for freelance recruiting, remote recruiter internship, student recruitment internships, freelance job placement, flexible hours recruiter jobs, remote talent acquisition intern, part-time hiring consultant, online recruiter jobs in India, freelance employment recruiter, recruiting for startups remotely, home-based recruitment roles, internships for aspiring recruiters, work-from-home staffing positions, freelance recruitment platforms, remote human resources internships, part-time recruiter jobs in India, virtual recruiting internships."
    },
    freeJobPosting: {
        title: "Post Jobs for Free | Reach Top Talent with EarlyJobs",
        description: "Post your job openings for free on EarlyJobs! Reach a wide pool of talent and simplify your hiring process with our user-friendly job posting platform.",
        keywords: "free job posting, post jobs for free, job listings India, free recruitment platform, advertise jobs online, job posting websites, free job ads, online job posting, post jobs in India, job board for employers, free job listing site, recruitment advertising, job vacancies posting, post jobs without cost, hiring solutions India, free employer services, job posting for startups, talent acquisition free service, job board for freshers, free job advertisement platform, recruit talent online, job posting for small businesses, free job listings for companies, online recruitment services, free job posting for employers, job postings for internships, job advertisement India, hiring opportunities for employers, post jobs easily, job portal for job seekers, no-cost job postings, recruitment made easy, free job board access, advertise vacancies for free, job posting tools, post freelance jobs, job opportunities for companies, simple job posting process."
    },
    login: {
        title: "Login for recruiter - Intern - Agencies - earlyjobs.in",
        description: "Log in to your EarlyJobs account to access job listings, manage applications, and connect with potential employers. Join us in shaping your career today!",
        keywords: "login to EarlyJobs, EarlyJobs sign in, job seeker login, recruiter login page, EarlyJobs account access, sign in to job portal, user login for EarlyJobs, job application login, employer login, access EarlyJobs dashboard, job seeker account login, EarlyJobs member login, recruitment portal login, login for job seekers, EarlyJobs profile access, employee login page, login for recruiters, secure login EarlyJobs, job listings login, user sign-in page, job postings account access, EarlyJobs credentials, recover EarlyJobs password, create EarlyJobs account, job management login, access job applications, login for talent seekers, EarlyJobs user interface, member area login, job tracking login, sign in for employers, EarlyJobs access portal, candidate login, recruitment dashboard login, EarlyJobs community login, login assistance EarlyJobs, verify EarlyJobs account, sign in help, job search account login"
    },
    viewOpenings: {
        title: "Job Search - Latest Job - Recruitment - earlyjobs.in",
        description: "Explore the latest job openings on EarlyJobs! Find exciting career opportunities, internships, and part-time positions tailored for freshers and students.",
        keywords: "view job openings, current job vacancies, job listings India, available positions, job openings near me, search for jobs, latest job postings, entry-level job openings, internships available, part-time job listings, full-time job vacancies, find job opportunities, recent job openings, career opportunities India, job search portal, freshers job listings, job opportunities for students, job openings by category, online job listings, recruitment openings, remote job opportunities, job alerts India, hiring now, job postings for freshers, explore job vacancies, job openings in BPO, seasonal job listings, urgent job openings, view all job listings, job search by location, opportunities for young professionals, browse job openings, job positions available, skill-based job listings, internships and jobs, job search platform, find jobs in India, job openings for recent graduates, career openings."
    },
    whyEarlyjobs: {
        title: "Why Choose EarlyJobs? | Your Partner in Career Development",
        description: "Discover the unique benefits of EarlyJobs! Learn how our platform supports early career development, connects talent with opportunities, and simplifies hiring.",
        keywords: "why choose EarlyJobs, benefits of EarlyJobs, EarlyJobs features, job portal advantages, early career support, student job opportunities, recruitment platform benefits, hiring solutions for freshers, unique features of EarlyJobs, advantages for employers, user-friendly job portal, early career development, job search made easy, EarlyJobs for startups, community for job seekers, recruitment assistance, free job postings, talent acquisition support, networking opportunities, why use EarlyJobs, early career resources, success stories on EarlyJobs, platform for fresh graduates, streamlined hiring process, accessible job listings, support for job seekers, platform for internships, trusted job portal, career growth opportunities, efficient job matching, exclusive job openings, local job opportunities, industry connections, resources for employers, innovative recruitment solutions, dedicated support for users, testimonials about EarlyJobs, platform for young professionals, why job seekers prefer EarlyJobs."
    },
    about: {
        title: "We are a leading recruitment solutions provider in India",
        description: "Know about EarlyJobs, our mission, and the team dedicated to supporting early career development. Discover how we connect job seekers and employers effectively.",
        keywords: "about EarlyJobs, EarlyJobs overview, mission of EarlyJobs, vision of EarlyJobs, who we are, EarlyJobs team, early career platform, company background, job portal mission, values of EarlyJobs, early career support, about our services, EarlyJobs for freshers, recruitment platform goals, how EarlyJobs works, community for job seekers, dedicated to young professionals, why EarlyJobs was created, fostering talent, empowering job seekers, about our mission, early employment opportunities, our approach to recruitment, history of EarlyJobs, commitment to employers, building careers, shaping futures, online job portal, job opportunities for students, about our vision, connecting talent with employers, innovation in recruitment, inclusive job platform, support for job seekers, our impact on careers, testimonials about EarlyJobs, resources for job seekers, dedication to quality hiring, about our community, how we help freshers."
    },
    team: {
        title: "Meet Our Team | Dedicated Professionals at EarlyJobs",
        description: "Meet the dedicated team at EarlyJobs! Our experts in recruitment and career development are committed to connecting talent with opportunities for success.",
        keywords: "meet our team, EarlyJobs team members, about our team, team at EarlyJobs, leadership team, recruitment experts, dedicated professionals, our team values, team profiles, team experience, career development experts, early career specialists, hiring professionals, diverse team, team mission, team achievements, backgrounds of our team, about the founders, team collaboration, staff qualifications, recruitment specialists, supporting job seekers, team culture, team commitment, industry experts, innovation team, meet the leadership, professional team at EarlyJobs, profiles of team members, early careers support team, team dedication, roles within our team, community engagement, expertise in recruitment, early talent advisors, mentorship team, team vision, how we work together, team impact, connecting talent with opportunities."
    },
    itRecruitment: {
        title: "IT Recruitment Services | Connect with Top Tech Talent - EarlyJobs",
        description: "Discover expert IT recruitment services at EarlyJobs. Connect with skilled tech talent and find the right professionals to meet your hiring needs today!",
        keywords: "IT recruitment services, tech talent acquisition, IT job openings, hiring IT professionals, IT recruitment agency, software developer recruitment, IT staffing solutions, IT job portal, recruitment for tech roles, IT jobs for freshers, technology recruitment services, digital talent hiring, IT career opportunities, job vacancies in IT, recruiting for startups, IT industry hiring, tech job listings, IT internships available, hiring for software engineers, tech recruitment strategies, IT job market trends, specialized IT recruitment, remote IT jobs, IT recruitment process, entry-level IT positions, skilled IT professionals, IT job search, IT roles and responsibilities, recruitment for tech companies, talent sourcing in IT, IT recruitment consultancy, job postings in technology, recruitment for developers, IT job descriptions, early career in IT, IT recruitment trends, connecting tech talent, job opportunities in IT, IT project recruitment."
    },
    financeRecruitment: {
        title: "Top Finance & Accounting Recruitment Services | EarlyJobs.in",
        description: "Discover the best finance and accounting recruitment services at EarlyJobs.in. Connect with top talent and streamline your hiring process for a successful workforce.",
        keywords: "finance recruitment agency, accounting recruitment agency, finance job openings, accounting job openings, financial analyst recruitment, accounting clerk recruitment, finance jobs near me, accounting jobs near me, top finance recruiters, top accounting recruiters, finance executive recruitment, accounting manager recruitment, finance job placement services, accounting job placement services, finance staffing solutions, accounting staffing solutions, financial services hiring, accounting talent search, finance career opportunities, accounting career opportunities, recruitment for finance roles, recruitment for accounting roles, financial job listings, accounting job listings, finance workforce recruitment, accounting workforce recruitment, finance employment agency, accounting employment agency, finance recruitment specialists, accounting recruitment specialists, finance job recruitment services, accounting job recruitment services, finance and accounting careers, entry-level finance jobs, entry-level accounting jobs, finance professionals recruitment, accounting professionals hiring, finance job market, accounting job market, finance recruitment trends"
    },
    salesRecruitment: {
        title: "Expert Sales & Marketing Recruitment Services | EarlyJobs.in",
        description: "Unlock your business potential with EarlyJobs.in's specialized sales and marketing recruitment services. Connect with top talent to drive your growth and success.",
        keywords: "sales recruitment agency, marketing recruitment agency, sales job openings, marketing job openings, sales executive recruitment, marketing executive recruitment, sales jobs near me, marketing jobs near me, top sales recruiters, top marketing recruiters, sales manager recruitment, marketing manager recruitment, sales job placement services, marketing job placement services, sales staffing solutions, marketing staffing solutions, sales talent acquisition, marketing talent acquisition, sales career opportunities, marketing career opportunities, recruitment for sales roles, recruitment for marketing roles, sales job listings, marketing job listings, sales workforce recruitment, marketing workforce recruitment, sales employment agency, marketing employment agency, sales recruitment specialists, marketing recruitment specialists, sales job recruitment services, marketing job recruitment services, sales and marketing careers, entry-level sales jobs, entry-level marketing jobs, sales professionals recruitment, marketing professionals hiring, sales job market, marketing job market, sales recruitment trends."
    },
    topExecutiveRecruitment: {
        title: "Premier Executive Recruitment Firm | EarlyJobs.in",
        description: "Discover top-tier executive recruitment services at EarlyJobs.in. We specialize in connecting businesses with exceptional leadership talent to drive success and growth.",
        keywords: "executive recruitment agency, top executive recruiters, executive search firm, leadership recruitment, executive job openings, executive positions, senior management recruitment, C-suite recruitment, executive talent acquisition, executive hiring solutions, executive job placement services, executive staffing agency, top-level recruitment, executive career opportunities, recruitment for executives, leadership talent search, executive job listings, executive workforce recruitment, recruitment for C-suite roles, executive recruitment specialists, high-level recruitment, executive talent management, executive employment agency, best executive recruitment firms, senior executive jobs, VP recruitment agency, executive search services, leadership hiring agency, executive recruitment trends, executive headhunters, executive job market, executive role recruitment, strategic recruitment agency, global executive recruitment, recruitment for senior leaders, executive professionals hiring, top executive search firms."
    },
    hrExecutiveRecruitment: {
        title: "HR Executive Recruitment Services | EarlyJobs.in",
        description: "Find top HR executive talent with EarlyJobs.in's specialized recruitment services. We connect businesses with skilled HR professionals to enhance your workforce.",
        keywords: "HR recruitment agency, HR executive recruitment, HR job openings, HR manager recruitment, HR positions, human resources staffing, HR talent acquisition, HR job placement services, HR staffing solutions, HR professionals recruitment, HR job listings, executive HR recruitment, HR workforce recruitment, recruitment for HR roles, HR employment agency, top HR recruiters, HR career opportunities, senior HR recruitment, HR executive search firm, HR hiring solutions, recruitment for HR executives, HR recruitment specialists, HR consultant recruitment, HR leadership roles, HR specialist recruitment, HR executive talent, recruitment for human resources, HR job market, HR headhunters, strategic HR recruitment, entry-level HR jobs, HR development recruitment, HR team recruitment, global HR recruitment, HR services agency, recruitment for HR managers, HR operations recruitment, HR professionals hiring, HR executive positions."
    },
    franchiseWithUs: {
        title: "Join Our Recruitment Franchise Network | Best Franchise Recruitment in India | EarlyJobs.in",
        description: "Explore exciting franchise opportunities with EarlyJobs.in. Partner with us to grow your business and benefit from our proven model and support system.",
        keywords: "franchise opportunities, join a franchise, franchise business model, franchise with us, start a franchise, franchise investment, best franchise opportunities, franchise partnership, franchise application, franchise support, franchise requirements, low-cost franchises, franchise business opportunities, top franchises to own, franchise system, successful franchises, franchise recruitment, franchise network, franchising benefits, franchise marketing, franchise ownership, franchise training, franchise consulting, franchise agreements, franchise leads, franchise directory, franchise options, multi-unit franchises, franchise development, franchise success stories, franchise ownership costs, franchise industry trends, local franchise opportunities, online franchise opportunities, franchise growth potential, franchise management, franchise opportunities near me, start a franchise business, franchise consulting services."
    },
    partnerWithUs: {
        title: "Partner with EarlyJobs.in for Success | Join Us Today",
        description: "Partner with EarlyJobs.in to expand your reach and enhance your business. Discover collaborative opportunities that drive success and mutual growth.",
        keywords: "partner with us, business partnership opportunities, strategic partnerships, collaboration opportunities, join our network, partnership programs, partner recruitment, affiliate partnership, partner benefits, business alliances, partnership agreements, successful partnerships, partnership support, growth through partnerships, partner resources, strategic collaboration, partner with a leader, business growth partnership, partnership opportunities near me, partner incentives, partnership development, join our team, partner initiatives, franchise partnership, partnership solutions, partner marketing, local business partnerships, partnership strategies, joint ventures, build a partnership, partner engagement, successful collaborations, business growth strategies, partnership consulting, partner onboarding, partner success stories, partnership programs for businesses, mutual growth opportunities, innovative partnerships, partner with industry leaders."
    },
    termsAndConditions: {
        title: "Terms and Conditions | EarlyJobs.in",
        description: "Review the terms and conditions for using EarlyJobs.in. Understand your rights and responsibilities while accessing our services and platform.",
        keywords: "terms and conditions, website terms of service, user agreement, service terms, privacy policy, legal terms, website usage policy, conditions of use, terms of use, online service agreement, user rights and responsibilities, website rules, terms and conditions template, online terms and conditions, legal disclaimers, user agreement template, service agreement terms, liability disclaimer, intellectual property rights, terms for using a website, conditions for service use, terms of engagement, compliance policy, user conduct guidelines, acceptable use policy, terms and conditions examples, website legal information, agreement terms, data protection terms, user responsibilities, service usage terms, governing law, modifications to terms, enforceability of terms, user consent, limitations of liability, user obligations, website terms summary."
    },
    privacyPolicy: {
        title: "Privacy Policy | EarlyJobs.in",
        description: "Read EarlyJobs.in's Privacy Policy to understand how we collect, use, and protect your personal information. Your privacy is our priority.",
        keywords: "privacy policy, data protection policy, user privacy, personal information security, privacy statement, online privacy policy, data collection practices, information usage policy, user data rights, privacy agreement, confidentiality policy, website privacy practices, privacy compliance, GDPR policy, CCPA compliance, data handling procedures, secure data practices, user consent policy, privacy rights, online data protection, personal data management, privacy guidelines, third-party data sharing, data security measures, information privacy law, user tracking policy, digital privacy, privacy issues, data retention policy, privacy best practices, data privacy regulations, information use disclosure, privacy policy examples, user information protection, privacy policy update, online tracking policies, privacy safeguards, transparency in data use, user data protection."
    },
    recruitmentOutsourcing: {
        title: "Recruitment Process Outsourcing (RPO) Services | EarlyJobs.in",
        description: "Streamline your hiring with EarlyJobs.in's Recruitment Process Outsourcing (RPO) services. Enhance efficiency and access top talent while reducing recruitment costs.",
        keywords: "recruitment process outsourcing, RPO services, recruitment outsourcing solutions, hiring process outsourcing, RPO providers, benefits of RPO, recruitment efficiency, outsourcing recruitment, RPO companies, talent acquisition outsourcing, end-to-end recruitment services, RPO solutions, workforce outsourcing, recruitment strategy outsourcing, RPO management, flexible recruitment solutions, improve hiring processes, recruitment agency outsourcing, talent management services, RPO best practices, outsourced recruitment process, RPO industry trends, cost-effective recruitment, customized RPO services, strategic recruitment outsourcing, RPO partnerships, recruitment process management, candidate sourcing outsourcing, RPO case studies, scaling recruitment, HR outsourcing services, talent acquisition strategies, effective recruitment solutions, RPO for small businesses, recruitment outsourcing benefits, comprehensive RPO services, RPO for high-volume hiring, RPO consulting services, agile recruitment outsourcing."
    },
    valueStaffingService: {
        title: "Value Staffing Services | EarlyJobs.in",
        description: "Discover value-driven staffing services at EarlyJobs.in. We connect businesses with skilled talent to meet their workforce needs efficiently and effectively.",
        keywords: "value staffing services, affordable staffing solutions, cost-effective staffing, staffing agency services, recruitment staffing services, staffing for small businesses, flexible staffing solutions, workforce management services, temporary staffing services, permanent staffing solutions, skilled labor staffing, staffing for startups, staffing and recruitment agency, value-driven recruitment, staffing strategies, talent acquisition services, employee staffing solutions, on-demand staffing, workforce staffing solutions, project-based staffing, staffing agency near me, staffing services for companies, specialized staffing services, staffing solutions for growth, managed staffing services, contract staffing services, professional staffing agency, staffing efficiency, workforce optimization, reliable staffing services, staffing support services, industry-specific staffing, value-added staffing, quality staffing services, staffing process outsourcing, customized staffing solutions, strategic staffing partnerships, temporary to permanent staffing."
    },
    itStaffingService: {
        title: "IT Staffing Services | EarlyJobs.in",
        description: "Find top IT talent with EarlyJobs.in's specialized IT staffing services. We connect businesses with skilled professionals to meet your technology needs effectively.",
        keywords: "IT staffing services, technology staffing solutions, IT recruitment agency, IT talent acquisition, tech staffing services, software development staffing, IT workforce solutions, IT contractor services, managed IT staffing, IT staffing firms, IT project staffing, temporary IT staffing, permanent IT placement, specialized IT recruitment, IT staffing consultants, IT staffing for startups, remote IT staffing, IT job placements, contract IT staffing, full-time IT staffing, IT talent management, IT staff augmentation, technical recruitment services, IT employee leasing, IT skills sourcing, staffing solutions for IT, IT resource management, IT staffing specialists, enterprise IT staffing, IT hiring solutions, agile IT staffing, recruitment for IT roles, IT staffing trends, IT service providers, IT professional placement, IT recruitment solutions, on-demand IT staffing, customized IT staffing services."
    },
}